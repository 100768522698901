import React, { useState, useEffect } from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Header3 from "../components/layouts/Header3";
import Footer from "../components/layouts/Footer";
import About from "../components/sections/About";
import Blogs from "../components/sections/Blogs";
import BrandlogosDark from "../components/sections/BrandlogosDark";
import Contact from "../components/sections/Contact";
import Academics from "../components/sections/Experiences";
import Herosection from "../components/sections/Herosection";
import Certifications from "../components/sections/Pricing";
import Services from "../components/sections/Services";
import Works from "../components/sections/Works";
import Newtimeline from "../components/elements/NewTimeline";
import HashLoader from "react-spinners/HashLoader";
import "./hey.css";
import SimpleForm from "./SimpleForm";

function Homepage3() {
  document.body.classList.add("dark");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4500);
  }, []);
  return (
    <>
      {loading ? (
        <div className="clip">
          <HashLoader
            color={"#66fcf1"}
            loading={loading}
            // css={override}
            size={150}
          />
        </div>
      ) : (
        <>
          <Header3 logoSource="/images/logo.svg" />
          <main className="content-3">
            <Element name="section-home">
              <ReactCursorPosition>
                <Herosection />
              </ReactCursorPosition>
            </Element>
            <Element name="section-about">
              <About />

              <Services />
            </Element>

            {/* <Element name="section-services">
         
            </Element> */}

            <Element name="section-work">
              <Newtimeline />

              <Certifications />

              <BrandlogosDark />
            </Element>
            <div className="spacer" data-height="200"></div>

            <Element name="section-academics">
              <Academics />
            </Element>
            {/* <div className="spacer" data-height="00"></div> */}

            <Element name="section-works">
              <Works />
            </Element>
            {/* <div className="spacer" data-height="100"></div> */}

            <Element name="section-brandlogos"></Element>

            <Element name="section-blogs">
              <Blogs />
            </Element>

            {/* <div className="spacer" data-height="200"></div> */}
            <Element name="section-contact">
              <Contact />
            </Element>
            <div className="spacer" data-height="100"></div>
            {/* <Element name="section-testimoninal"></Element> */}
            <SimpleForm />
          </main>
          <Footer />
        </>
      )}
    </>
  );
}

export default Homepage3;
