import React from "react";
import Skill from "../elements/skills/skills.component";
import Pagetitle from "../elements/Pagetitle";
import ScrollAnimation from "react-animate-on-scroll";

function BrandlogosDark() {
  return (
    <ScrollAnimation
      animateOut="bounceOutLeft"
      animateIn="slideInLeft"
      // duration={5}
      // animateOnce={true}
    >
      <section id="branglogos">
        <div className="container">
          <Pagetitle
            title="Technical Skills"
            image="/images/com3-gif-maker-unscreen.gif"
          />

          <div className="row">
            <Skill />
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
}

export default BrandlogosDark;
