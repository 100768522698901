import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./hello.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Footbox from "../elements/footbox";

function Footer({ light }) {
  const Info = [
    {
      id: 1,
      title: "Hobbies",
      content:
        "A few of my hobbies include doing financial investments, learning new technologies, playing basketball, building PCs, playing video game like League of Legends, and going to the gym.",
      icon: "🕺",
    },
    {
      id: 2,
      title: "What keeps me going.... ",
      icon: "🚶",
      content:
        "You're probably curious as to what motivates me as you clicked the button. As a result of your curiosity, here it is: When you viewed my résumé or portfolio, you were probably wondering as to why I had so many jobs and extracurricular activities while maintaining exceptional marks in college. To answer your question, I am determined to succeed because many people are unaware that I am a first-generation college student and a first-generation immigrant to the United States. I frequently worked two jobs while in college and took an average of 18 units a semester. I am this way because I came here at the age of 16 as a junior in high school. While challenges have undoubtedly arisen, I am determined to work hard and persevere to improve continually.",
    },
    {
      id: 3,
      title: "My Passion For Tech",
      content:
        "After being inspired by a high school teacher, I quickly began my adventure into computer networks. Understanding networks was such an incredible subject that you could link one piece of hardware to another simply by understanding how they communicate. Then I taught myself how to develop applications utilizing several frameworks, which evolved into full-stack development over time. I frequently had to consider how to deploy. As a result, I quickly identified cloud computing as a powerful tool for infrastructure development, and I believe cloud computing is redefining how applications are created and delivered today. Previously, we had to rent or purchase a server; now, we only pay for what we use. Containerization is another topic that has attracted my interest. We can deploy our code using Docker, Kubernetes, and other technologies with a few commands, eliminating the need to think about server provisioning.",
      icon: "❤️‍🔥",
      content2: "Isn't that cool?",
    },
  ];
  return (
    <ScrollAnimation animateIn="bounceInLeft" animateOut="bounceOutLeft">
      <footer className={light ? "footer light" : "footer"}>
        <ScrollAnimation animateIn="bounce" initiallyVisible={true}>
          <h3 style={{ color: "#66fcf1" }}>Secret Info...</h3>
        </ScrollAnimation>
        <div className="container">
          <div
            className="row 
        "
          >
            {Info.map((infoItem) => (
              <div className="col-md-4   middle2" key={infoItem.id}>
                <Footbox infoData={infoItem} />
              </div>
            ))}
          </div>
        </div>
      </footer>
    </ScrollAnimation>
  );
}
export default Footer;
