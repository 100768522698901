let timelineElements = [
  {
    id: 22,
    title: "Software Engineer",
    location: "USA",
    company: "Walmart Global Tech",
    description: [
      "Developed and maintain 7 Staff Engineer Level AI chatbots that serve 132 unique users, ranging from Software Engineers to Staff Engineers, driving significant cost savings of $1.3 million annually.",
      "Led the AI integration into IDE for our team and successfully navigated the approval process, resulting in a 20% productivity boost and saving approximately $250k annually for 8 engineers.",
      "Led feature development using (React.JS, Typescript) in Walmart's RX Delivery Project (RX PD), consistently achieving an average Jira story velocity completion of 1.5 stories per day, 88% more than the standard requirement.",
      "Led the development of analytic dashboards using Splunk, significantly enhancing monitoring capabilities and improving operational efficiency and decision-making processes.",
      "Helped establish better coding standards and streamlined the pull request CI/CD flow, reducing friction in pull requests, enhancing code quality, and increasing team efficiency.",
    ],
  },
  {
    id: 13,
    title: "Production Engineer Intern",
    location: "Seattle, WA",
    company: "Meta",
    description: [
      "Designed and built a new client layer that is used by Facebook.com Web Real Time Traffic client. This enables web client throttling to occur on the client side rather than on the load balancer or end server, thus saving routing system resources. The layer is used at an average rate of 150k requests/sec.",
      "Developed throttling features for Facebook's Web, iOS, and Android Real Time Traffic clients. These provide customers options to throttle clients granularly or through automation. These new features are used at an average rate of 300k requests/sec.",
      "Created and rolled out 9 total features across front-end (React.JS,Typescript) and back-end (Python, GraphQL, PHP). These features are used to help customers control their traffic and improve use of system resources.",
    ],
    desc: ["My Meta Swag", "My Meta Swag", "My Meta Swag"],
    images: [
      "https://jtagaca.live/images/MetaPhoto1.jpg.png",
      "https://jtagaca.live/images/MetaPhoto2.jpg.png",
      "https://jtagaca.live/images/MetaPhoto3.jpg.png",
    ],
    date: "05/2022 - 08/2022",
    icon: "work",
    role: "Software Engineer or Site Reliability Engineer",
  },
  {
    // //TODO  official title and role
    id: 14,
    title: "Student Web Developer",
    location: "Remote",
    company: "California State University, Bakersfield",
    // images: ["/images/n.jpg", "/images/n.jpg", "/images/n.jpg"],

    description: [
      "Designed new web pages for the Pschology website using React.JS.",
      "Maintained the Psychology website and created new features in response to the request of the Chair of the Psychology department at CSUB.",
    ],

    buttonText: "View Backend Projects",
    date: "01/2022 - 05/2022",
    icon: "work",
    role: ["Web Developer", "Web Designer", "Consultant"],
  },
  {
    id: 1,
    title: "IT Auditor",
    location: "Bakersfield, CA",
    company: "Valley Strong Credit Union",
    description: [
      "Automated 30+ excel-based reports to a SQL-based self-sustaining automated report using power automate, which decreased the time required to provision a report by 87% (.5/4hr) on a weekly basis. ",
      "Architected, deployed, and maintained data pipelines that enabled automated and efficient reporting through Powershell scripts, Excel, SQL, and Power BI, which boosted report efficiency by 34%. ",
      "Quality assured and debugged vast amounts of data sets to prevent false positives on the data.",
    ],
    // images: ["/images/n.jpg", "/images/n.jpg", "/images/n.jpg"],
    date: "01/2021 - 05/2022",
    icon: "work",
    role: "IT Auditor",
  },
  {
    id: 11,
    title: "Information Technology Intern",
    location: "Remote",
    company: "Google",
    description: [
      "Performed data analytics and visualizations on a 300+GB data set using internal tools, Data Studio, SQL, and Regex, allowing company stakeholders to make business decisions for a team consisting of thousands of employees, and handles an average of 2-4k tickets per day.",
      "Created SQL and Regex scripts to extract URLs from a 300+GB data set, identifying potentially disoriented articles and allowing IT to fix them, reducing the need for customers to contact IT Support Engineers for issues.",
      "Engineered and rolled out data pipelines that reduced the time spent extracting, transforming, and loading data by 90% allowing fast visualizations.",
    ],
    desc: ["My Google Swag", "My Google Swag", "My Noogler Hat/ Google Hat"],
    images: ["/images/g2.jpg", "/images/g3.jpg", "/images/g1.jpg"],
    date: "06/2021 - 08/2021",
    icon: "work",
    role: "IT Auditor",
  },
  {
    id: 10,
    title: "Software Developer Program",
    location: "Remote",
    company: "IBM",
    description: [
      "Highly Selective Program: One of the 273 undergraduate students to be selected for the Software Developer Track in the US.",
      "Led a four-person team in developing UI components and back end routes using React.js and Express.js, as well as deploying to IBM Cloud.",
      "Was in the top 4% of students (12/273), receiving a 106/100 on the program's overall grade. To complete the program, participants were required to participate in semi-weekly labs using MVC architecture and semi-weekly hacker rank questions geared toward full-stack web development.",
    ],

    images: [
      "images/ibm2.jpg",
      "images/ibm1.jpg",
      "images/ibm3.jpg",
      "images/ibm4.jpg",
      "images/ibm5.jpg",
    ],
    desc: [
      "My IBM Swag",
      "My IBM Swag",
      "My IBM Swag",
      "My IBM Badge",
      "IBM scores: 12/273 students has a score of 106",
    ],

    date: "06/2021 - 07/2021",
    icon: "work",
    role: "IT Auditor",
  },

  {
    // //TODO  official title and role
    id: 2,
    title: "Student Web Developer",
    location: "Remote",
    company: "California State University, Bakersfield",
    // images: ["/images/n.jpg", "/images/n.jpg", "/images/n.jpg"],

    description: [
      "Converted and redesigned 20+ HTML pages to React.JS and Express.JS which enabled more performant and readable code.",
      "Modernized the website's user interface, allowing students to find information 40% faster and streamline faculty recruitment for student research assistant positions.",
    ],

    buttonText: "View Backend Projects",
    date: "01/2021 - 05/2021",
    icon: "work",
    role: ["Web Developer", "Web Designer", "Consultant"],
  },
  {
    id: 3,
    title: "Student Ambassador",
    company: "Microsoft",

    images: [
      "images/microsoft.jfif",
      "images/mc1.png",
      "images/mc2.png",
      "images/mc3.png",
    ],
    desc: [
      "Event hosted for learning Git,Github and React",
      "My Microsoft Swag",
      "My Microsoft Swag",
      "My Microsoft Swag",
    ],
    role: "IT Audit Technician",
    location: "Remote",

    // location: "South Warren, Geshington",
    description: [
      " Represented Microsoft and organized community events that encouraged students to learn and develop their careers in technology.",
      "Advocated the use of technology with improving the quality of life, education, environment and more.",
    ],
    date: "01/2021 - Current",
    icon: "work",
  },

  {
    id: 4,
    title: "IT Admin",
    company: "Valley Strong Credit Union",
    location: "Bakersfield, CA",
    // images: ["/images/n.jpg", "/images/n.jpg", "/images/n.jpg"],

    // location: "South Warren, Geshington",
    role: "IT Audit Technician",

    description: [
      " Made production scripts for 600+ computers with 90% success rate.",
      "Administrator for emails,licenses,active directory,vid center/verafin, and phone system.",
      "Provided end-user troubleshooting and desktop support on Windows and Mac systems through KACE ticketing system.",
    ],
    date: "12/2019 - 12/2020",
    icon: "work",
  },
  {
    id: 4,
    title: "Software Engineering Program",
    location: "Remote",
    company: "JP Morgan Chase",

    description: [
      "Modified existing graphing interface to correct coding errors, upgrade UI and improve overall performance.",
      "Adjusted and debugged source code for stock price data feed that has boosted computational efficiency by 10%.",
      "Developed with Python 3, node.js, C++ to produce efficient and validation-ready code for project specifications.",
    ],

    date: "09/2020 - 10/2020",
  },
  {
    id: 5,
    title: "Information Technology Assistant",
    location: "Bakersfield, CA",
    company: "California State University, Bakersfield",

    description: [
      "Used ServiceNow ticketing system to manage and process support actions and requests.",
      "Assisted students,faculty staff, and teachers via live chat, web conference and phone to resolve issues related to Blackboard,Office 365,and mycsub account issues.",
    ],
    date: "10/2019 - 12/2019",
  },
  {
    id: 6,
    title: "Bank Teller",
    company: "Valley Strong Credit Union",

    location: "Bakersfield, CA",

    description: [
      "Received cash and checks for deposit, including verifying amounts and endorsements and examining cash to prohibit acceptance of counterfeit bills.",
      " Helped customers open and close accounts, apply for loans and make sound financial decisions.",
      "Demonstrated expertise in identifying and mitigating potential fraud and transaction risks.",
    ],
    date: "10/2018 - 12/2019",
    icon: "school",
  },
];
const description = [
  {
    id: 1,
    description: [
      "Recommend changes for reporting to enhance the overall monitoring of system, vulnerabilities and practices",
      "Built data pipeline that allowed automated and efficient way of reporting using Powershell scripting, Excel , SQL and Power BI that",
      "Reviewed performance indicators and developed potential monitoring metrics.",
    ],
  },
];
export { description };
export default timelineElements;
