import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import emailjs, { init } from "emailjs-com";
import Button from "react-bootstrap/Button";

init("user_6ipLIaqZtFG58InfpdnQ4");

function Contact() {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [textContent, settextContent] = useState("Submit");

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const handleHover = () => {
    settextContent("Hold Down Click");
  };
  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.name) {
      setError(true);
      setMessage("Name is required");
    } else if (!formdata.email) {
      setError(true);
      setMessage("Email is required");
    } else if (!formdata.subject) {
      setError(true);
      setMessage("Subject is required");
    } else if (!formdata.message) {
      setError(true);
      setMessage("Message is required");
    } else {
      setError(false);
      var templateParams = {
        name: formdata.name,
        email: formdata.email,
        subject: formdata.subject,
        message: formdata.message,
      };
      // console.log(formdata.name);
      // console.log(formdata.email);
      // console.log(formdata.subject);
      // console.log(formdata.message);
      emailjs
        .send("service_birmkff", "template_uc04lg6", templateParams)
        .then(function(response) {
          setMessage("You message has been sent!!!");
          handleClear();
          // setFormdata("");
          // formdata.message("");
        });
    }
  };
  const handleClear = () => {
    Array.from(document.querySelectorAll("input")).forEach((input) => {
      input.value = "";
      // textarea.value = "";
    });
    Array.from(document.querySelectorAll("textarea")).forEach((textarea) => {
      textarea.value = "";
      // textarea.value = "";
    });
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
    setMessage("");
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4"> {message} </div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4"> {message} </div>;
    } else {
      return null;
    }
  };

  return (
    <section id="contact">
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        // animateOnce={true}
      >
        <div
          className="container "
          style={{
            marginBottom: 150,
          }}
        >
          <Pagetitle
            title="Get in Touch"
            image="/images/com5-gif-maker-unscreen.gif"
          />
          <div className="row">
            <div
              className="col-md-4"
              style={{
                color: "#66fcf1 ",
              }}
            >
              <div className="contact-info">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                ></ScrollAnimation>{" "}
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p
                    className=" rounded padding-30"
                    style={{
                      backgroundColor: "#fffff0",
                      color: "black",
                    }}
                  >
                    Don 't like forms? Send me an{" "}
                    <a href="mailto:jtagaca@csub.edu"> email </a>. 👋{" "}
                  </p>{" "}
                </ScrollAnimation>{" "}
              </div>{" "}
            </div>
            <div className="col-md-8">
              <form
                id="contact-form"
                className="contact-form mt-6"
                onSubmit={submitHandler}
              >
                <div className="row">
                  <div className="column col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="InputName"
                        placeholder="Your name"
                        onChange={handleChange}
                        value={formdata.name}
                      />{" "}
                    </div>{" "}
                  </div>
                  <div className="column col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="InputEmail"
                        name="email"
                        placeholder="Your Email address"
                        onChange={handleChange}
                        value={formdata.email}
                      />{" "}
                    </div>{" "}
                  </div>
                  <div className="column col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="InputSubject"
                        name="subject"
                        placeholder="Subject"
                        onChange={handleChange}
                        value={formdata.subject}
                      />{" "}
                    </div>{" "}
                  </div>
                  <div className="column col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="InputMessage"
                        className="form-control"
                        rows="5"
                        placeholder="Message"
                        onChange={handleChange}
                        value={formdata.message}
                      ></textarea>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="text-center">
                  <Button
                    type="submit"
                    name="submit"
                    id="submit"
                    value="Submit"
                    className="btn rainbow2 ti-btn "
                    // className="btn btn-default"
                    onMouseOver={() => {
                      handleHover();
                    }}
                    onMouseLeave={() => {
                      settextContent("Submit");
                    }}
                  >
                    {" "}
                    {textContent}{" "}
                  </Button>{" "}
                </div>{" "}
              </form>{" "}
              {handleAlerts()}{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </ScrollAnimation>{" "}
    </section>
  );
}

export default Contact;
