import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Button from "react-bootstrap/Button";
import "./App.css";
function Timeline({ education }) {
  const { grade, title, content } = education;
  return (
    <div className="timeline-container heights">
      <div style={{ height: 120 }} className="content">
        <h3 className="">{title}</h3>

        <p>
          <strong>{content} </strong>
        </p>

        <div className="btn-static "> Grade: {grade}</div>
      </div>
    </div>
  );
}

export default Timeline;
