import React, { useState } from "react";
import "./App.css";
import Modal from "react-bootstrap/Modal";

import Card from "react-bootstrap/Card";
import Buttond from "react-bootstrap/Button";
import NJPG from "./n.jpg";

import { AiFillAccountBook } from "react-icons/ai";

function Footbox({ infoData }) {
  // use this for the values of image title and more
  const { title, icon, content, content2 } = infoData;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="row mt-3">
        <Buttond
          className="my-button rainbow2"
          variant=""
          onClick={handleShow}
          style={{ color: "#fffff0" }}
        >
          <span>{title}</span>
          {/* <AiFillAccountBook /> */}
          <span>{icon}</span>
        </Buttond>
      </div>

      <Modal
        size="lg"
        // dialogClassName="modalcontainer"
        className="modalcontainer"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="bg-white" closeButton={false}>
          <Modal.Title
            className=" rounded shadow-dark px-3 overflow-hidde"
            style={{ backgroundColor: "#66fcf1" }}
          >
            {title}

            <span>{icon}</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="bg-white">
          <p className="content4">{content}</p>
          <p className="content4">{content2}</p>

          {/* <img src={icon} alt={title} /> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Footbox;
