import L_HTML5 from "../../../assets/img/skills/html-5.svg";
import L_CSS3 from "../../../assets/img/skills/css3.svg";
import L_SASS from "../../../assets/img/skills/sass-1.svg";
import L_BOOTSTRAP from "../../../assets/img/skills/bootstrap-4.svg";
import L_REACT from "../../../assets/img/skills/react.svg";
import L_REDUX from "../../../assets/img/skills/redux.svg";
import L_REACT_ROUTER from "../../../assets/img/skills/react-router.svg";
import L_MATERIALUI from "../../../assets/img/skills/material-ui-1.svg";
import L_REACT_BOOTSTRAP from "../../../assets/img/skills/react-bootstrap.svg";
import L_STYLED_COMPONENTS from "../../../assets/img/skills/styled-components.svg";
import L_NODE_JS from "../../../assets/img/skills/nodejs.svg";
import L_EXPRESS from "../../../assets/img/skills/express.svg";
import L_DJANGO from "../../../assets/img/skills/django.svg";
import L_FLASK from "../../../assets/img/skills/flask.svg";
import L_MONGODB from "../../../assets/img/skills/mongodb.svg";
import L_POSTGRESQL from "../../../assets/img/skills/postgresql.svg";
import L_MSSQL from "../../../assets/img/skills/mssql.svg";
import L_GIT from "../../../assets/img/skills/git-icon.svg";
import L_HEROKU from "../../../assets/img/skills/heroku.svg";
import L_DIGITAL_OCEAN from "../../../assets/img/skills/digital-ocean.svg";
import L_GITHUB_PAGES from "../../../assets/img/skills/github.svg";
import L_JAVASCRIPT from "../../../assets/img/skills/javascript.svg";
import L_TYPESCRIPT from "../../../assets/img/skills/typescript.svg";
import L_GOLANG from "../../../assets/img/skills/go-6.svg";
import L_PYTHON from "../../../assets/img/skills/python.svg";
import Angular from "../../../assets/img/skills/angular.svg";
import AWS from "../../../assets/img/skills/aws.svg";
import azure from "../../../assets/img/skills/azure.svg";
import cpp from "../../../assets/img/skills/c++.svg";
import gcp from "../../../assets/img/skills/gcp.svg";
import kubernetes from "../../../assets/img/skills/kubernetes.svg";
import docker from "../../../assets/img/skills/docker.svg";
import graphql from "../../../assets/img/skills/graphql.svg";
import sequelize from "../../../assets/img/skills/sequelize.svg";
import mysql from "../../../assets/img/skills/mysql.svg";
import sagemaker from "../../../assets/img/skills/sagemaker.svg";
import hack from "../../../assets/img/skills/hack.png";
import php from "../../../assets/img/skills/php.svg";
import java from "../../../assets/img/skills/java.svg";

export const skills = {
  frontend: [
    {
      link: "https://en.wikipedia.org/wiki/HTML5",
      imgAltText: "HTML 5",
      imgSrc: L_HTML5,
      skillName: "HTML5",
    },
    {
      link: "https://medium.com/beginners-guide-to-mobile-web-development/whats-new-in-css-3-dcd7fa6122e1",
      imgAltText: "CSS 3",
      imgSrc: L_CSS3,
      skillName: "CSS3",
    },
    {
      link: "https://www.javascript.com/",
      imgAltText: "JavaScript",
      imgSrc: L_JAVASCRIPT,
      skillName: "JavaScript",
    },
    {
      link: "https://sass-lang.com/",
      imgAltText: "Sass",
      imgSrc: L_SASS,
      skillName: "Sass",
    },
    {
      link: "https://getbootstrap.com/",
      imgAltText: "Bootstrap",
      imgSrc: L_BOOTSTRAP,
      skillName: "Bootstrap",
    },
    {
      link: "https://reactjs.org/",
      imgAltText: "React JS",
      imgSrc: L_REACT,
      skillName: "React JS",
    },
    {
      link: "https://redux.js.org/",
      imgAltText: "Redux",
      imgSrc: L_REDUX,
      skillName: "Redux",
    },
    {
      link: "https://reacttraining.com/react-router/",
      imgAltText: "React Router",
      imgSrc: L_REACT_ROUTER,
      skillName: "React Router",
    },
    {
      link: "https://material-ui.com/",
      imgAltText: "Material-UI",
      imgSrc: L_MATERIALUI,
      skillName: "Material-UI",
    },
    {
      link: "https://react-bootstrap.github.io/",
      imgAltText: "React Bootstrap",
      imgSrc: L_REACT_BOOTSTRAP,
      skillName: "React Bootstrap",
    },
    // {
    //   link: "https://styled-components.com/",
    //   imgAltText: "styled-components",
    //   imgSrc: L_STYLED_COMPONENTS,
    //   skillName: "styled-components",
    // },
  ],

  backend: [
    {
      link: "https://hacklang.org/",
      imgAltText: "Hack",
      imgSrc: hack,
      skillName: "Hack",
    },
    {
      link: "https://nodejs.org/en/",
      imgAltText: "Node",
      imgSrc: L_NODE_JS,
      skillName: "Node.js",
    },
    {
      link: "https://expressjs.com/",
      imgAltText: "Express",
      imgSrc: L_EXPRESS,
      skillName: "Express",
    },
    {
      link: "https://graphql.org/",
      imgAltText: "GraphQL",
      imgSrc: graphql,
      skillName: "GraphQL",
    },
  ],

  machineLearning: [
    {
      link: "https://aws.amazon.com/sagemaker/",
      imgAltText: "AWS SageMaker",
      imgSrc: sagemaker,
      skillName: "AWS SageMaker",
    },
  ],

  Container: [
    {
      link: "https://www.docker.com/",
      imgAltText: "Docker",
      imgSrc: docker,
      skillName: "Docker",
    },
    {
      link: "https://kubernetes.io/",
      imgAltText: "Kubernetes",
      imgSrc: kubernetes,
      skillName: "Kubernetes",
    },
  ],
  hostingPlatforms: [
    {
      link: "https://aws.amazon.com/?nc2=h_lg",
      imgAltText: "AWS",
      imgSrc: AWS,
      skillName: "Amazon Web Services",
    },
    {
      link: "https://azure.microsoft.com/en-us/",
      imgAltText: "Microsoft Azure",
      imgSrc: azure,
      skillName: "Azure",
    },
  ],
  programmingLanguages: [
    {
      link: "https://www.javascript.com/",
      imgAltText: "JavaScript",
      imgSrc: L_JAVASCRIPT,
      skillName: "JavaScript",
    },
    {
      link: "https://www.php.net/",
      imgAltText: "PHP",
      imgSrc: php,
      skillName: "PHP",
    },
    // {
    //   link: "https://www.typescriptlang.org/",
    //   imgAltText: "TypeScript",
    //   imgSrc: L_TYPESCRIPT,
    //   skillName: "TypeScript",
    // },
    {
      link: "https://www.python.org/",
      imgAltText: "Python",
      imgSrc: L_PYTHON,
      skillName: "Python",
    },

    {
      link: "https://www.php.net/",
      imgAltText: "Java",
      imgSrc: java,
      skillName: "Java",
    },
    {
      link: "https://www.cplusplus.com/",
      imgAltText: "C++",
      imgSrc: cpp,
      skillName: "C++",
    },
  ],
  databases: [
    {
      link: "https://www.postgresql.org/",
      imgAltText: "PostgreSQL",
      imgSrc: L_POSTGRESQL,
      skillName: "PostgreSQL",
    },
    {
      link: "https://www.microsoft.com/en-us/sql-server/sql-server-2019",
      imgAltText: "MS-SQL",
      imgSrc: L_MSSQL,
      skillName: "Microsoft SQL Server",
    },
    {
      link: "https://www.mysql.com/",
      imgAltText: "MySql",
      imgSrc: mysql,
      skillName: "MySQL",
    },
    {
      link: "https://www.mongodb.com/",
      imgAltText: "MongoDB",
      imgSrc: L_MONGODB,
      skillName: "MongoDB",
    },
  ],
  versionControl: [
    {
      link: "https://git-scm.com/",
      imgAltText: "GIT",
      imgSrc: L_GIT,
      skillName: "GIT",
    },
  ],
};
