import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
function Portfolio({ portfolio }) {
  const {
    category,
    title,
    image,
    images,
    demolink,
    codesource,
    content,
    technology,
    challenge,
    ytlink,
    group,
    task,
    architecttitle,
  } = portfolio;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDemolink = () => {
    const url = demolink;
    // console.log(url);
    window.open(url, "_blank");
  };
  const handleCodeSource = () => {
    const url = codesource;
    window.open(url, "_blank");
  };

  return (
    <div>
      <div className="teamSelector " onClick={handleShow}>
        <div className="portfolio-item rounded shadow-bg-white ">
          <div className="details container-fluid">
            <div className="row text-center middle2 ">
              {technology &&
                technology.map((tb) => {
                  return (
                    <span
                      key={tb}
                      className="term col-lg-3 col-md-3 col-sm-4 text-capitalize mt-0 mx-1 rainbow2"
                    >
                      {tb}
                    </span>
                  );
                })}
            </div>
            <h4 className="title">{title}</h4>
          </div>
          <div style={{ background: "white" }} className="thumb heights ">
            <img
              style={{height: 250, width: "100%" }}
              src={image}
              alt="Portfolio-title"
            />
            <div className="mask"></div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        centered={true}
        // animation={true}
        show={show}
        onHide={handleClose}
        className="modal"
      >
        <Modal.Header closeButton={false} className="bg-white">
          <Modal.Title
            className=" rounded shadow-dark px-3 overflow-hidden"
            style={{ backgroundColor: "#66fcf1" }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <Card className="bg-white">
            <Carousel>
              {images &&
                images.map((tb, index) => {
                  return (
                    <Carousel.Item interval={2500}>
                      {tb.includes("youtube") ? (
                        <iframe
                          width="100%"
                          height="418"
                          src={tb}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen={true}
                        ></iframe>
                      ) : (
                        <div key={index}>
                          <img
                            className="d-block w-100 imagecarousel"
                            src={tb}
                            alt="First slide"
                          />

                          <Carousel.Caption>
                            <h3>{architecttitle && architecttitle[index]}</h3>
                            {/* <p>
                              Nulla vitae elit libero, a pharetra augue mollis
                              interdum.
                            </p> */}
                          </Carousel.Caption>
                        </div>
                      )}
                    </Carousel.Item>
                  );
                })}
            </Carousel>
            <Card.Body>
              {/* <Card.Title>Card Title</Card.Title> */}
              {content ? <h4>About</h4> : null}

              <Card.Text>{content}</Card.Text>
              {task ? group ? <h4>What I did</h4> : <h4>Context</h4> : null}

              <Card.Text>{task}</Card.Text>

              {challenge != null ? (
                <>
                  <h4>Challenge</h4> <Card.Text>{challenge} </Card.Text>
                </>
              ) : null}
            </Card.Body>
            <div className="row middle2">
              {technology &&
                technology.map((tb) => {
                  return (
                    <span
                      key={tb}
                      // style={{ fontWeight: 20 }}
                      //TODO make this wrapping better
                      className="text-center  font col-md-3 rounded m-1 gradient-border  px-1 mr "
                      style={{ backgroundColor: "#66fcf1 " }}
                    >
                      {tb}
                    </span>
                  );
                })}
            </div>
          </Card>
        </Modal.Body>
        <Modal.Footer className="text-center middle2">
          {demolink == null || demolink == "" ? null : (
            <Button
              className="rainbow2"
              variant="primary"
              onClick={handleDemolink}
            >
              Live Demo
            </Button>
          )}

          <Button
            className="rainbow2"
            variant="primary"
            onClick={handleCodeSource}
          >
            See Code
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Portfolio;
