import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";
import "./styles.css";
import Certification from "../elements/certification/experience.component";

import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";

import ScrollAnimation from "react-animate-on-scroll";

// Academics

const firstrow = [
  {
    id: 1,
    title: "COMM 1008",
    grade: "A",
    content: "Strategies of Public Speaking",
  },
  {
    id: 2,
    title: "ENGL 1109",
    grade: "A",
    content: "Writing and Research",
  },
  {
    id: 2,
    title: "MATH 2200",
    grade: "A",
    content: "Statistics",
  },
  {
    id: 3,
    title: "CMPS 2650",
    grade: "A",
    content: " Linux Administration",
  },
];

const secondRow = [
  {
    id: 1,
    title: "CMPS 3680",
    grade: "A",
    content: "Advanced Web Programming",
  },
  {
    id: 2,
    title: "CMPS 3390",
    grade: "A",
    content: "Application Development",
  },
  {
    id: 3,
    title: "CMPS 3120",
    grade: "A",
    content: "Algorithm Analysis",
  },
  {
    id: 4,
    title: "CMPS 3350",
    grade: "A",
    content: "Software Engineering",
  },
];

const thirdRow = [
  {
    id: 1,
    title: "CMPS 3420",
    grade: "A",
    content: "Database Systems",
  },

  {
    id: 2,
    title: "CMPS 3560",
    grade: "A",
    content: "Artificial Intelligence",
  },
  {
    id: 4,
    title: "CMPS 3600",
    grade: "A-",
    content: "Operating Systems",
  },
  {
    id: 3,
    title: "CMPS 3620",
    grade: "A",
    content: "Computer Networks",
  },
];
const Second = [
  {
    id: 1,
    title: "cmps3650",
    grade: "2019 - Present",
    content: "",
  },
  {
    id: 2,
    title: "Front-End Developer",
    grade: "2017 - 2013",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
  {
    id: 3,
    title: "Back-End Developer",
    grade: "2013 - 2009",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
  {
    id: 4,
    title: "Back-End Developer",
    grade: "2013 - 2009",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
];

function Experiences() {
  return (
    // <ScrollAnimation
    //   animateIn="fadeInUp"
    //   animateOut="fadeInOut"
    //   // animateOnce={true}
    // >
    <section id="experience" style={{ marginTop: 150, marginBottom: 150 }}>
      <div className="container">
        <Pagetitle
          title="Relevant Academic Courses"
          image="/images/com2-gif-maker-unscreen.gif"
        />

        <div className="row my-3">
          {firstrow.map((education) => (
            <div className="col-md-3 ">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                // animateOnce={true}
              >
                <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden my-2">
                  <Timeline
                    //TODO  make this the same height
                    // style={{ height: 100 }}
                    key={education.id}
                    education={education}
                  />
                </div>
              </ScrollAnimation>
            </div>
          ))}
        </div>

        <div className="row my-3">
          {secondRow.map((education) => (
            <div className="col-md-3 ">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                // animateOnce={true}
              >
                <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden my-2">
                  <Timeline key={education.id} education={education} />
                </div>
              </ScrollAnimation>
            </div>
          ))}
        </div>

        <div className="row my-3">
          {thirdRow.map((education) => (
            <div className="col-md-3 ">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                // animateOnce={true}
              >
                <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden my-2">
                  <Timeline key={education.id} education={education} />
                </div>
              </ScrollAnimation>
            </div>
          ))}
        </div>

        {/* <div className="row my-3 middle2 text-center">
          {thirdRow.map((education) => (
            <div className="col-md-3 ">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                // animateOnce={true}
              >
                <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden my-2">
                  <Timeline key={education.id} education={education} />
                </div>
              </ScrollAnimation>
            </div>
          ))}
        </div> */}

        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          // animateOnce={true}
        >
          <div className="row middle2">
            <div
              className="
           
            bg-white rounded shadow-dark text-center  mx-3 padding-30 overflow-hidden 
            float-end col-md-3 my-2"
            >
              <h2>Cummulative</h2>
              <TrackVisibility>
                {/* <Counter number={3.95} /> */}
                <h3>GPA: 3.96</h3>
              </TrackVisibility>
            </div>

            <div
              // style={{ height: 148 }}
              className="
              mx-3 text-center
            bg-white rounded shadow-dark padding-30 overflow-hidden 
            float-end col-md-3 my-2"
            >
              <h3>Graduation Date</h3>
              <TrackVisibility>
                <h3>May 2023</h3>
              </TrackVisibility>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </section>
    // </ScrollAnimation>
  );
}

export default Experiences;
