import React from "react";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";
import "./styles.css";

import ScrollAnimation from "react-animate-on-scroll";

const allBlogs = [
  {
    id: 1,
    title: "Microsoft Learn Student Ambassador",
    image: "images/microsoft.png",
    link: "https://studentambassadors.microsoft.com/",
    content1:
      "The Microsoft Learn Student Ambassadors (formerly called Microsoft Student Partners) is a program to sponsor students majoring in disciplines related to technology. The MSP (now MLSA) program enhances students' employability by offering training in skills not usually taught in academia, including knowledge of Microsoft technologies.",
    content2:
      "The program is available in most countries, and all students in college and university level are eligible to apply. If accepted, Student Partners are assumed to further share the knowledge among the academic community by, for example, arranging events or courses, giving presentations, and initiating projects.",
    images: [
      "images/microsoft.jfif",
      "images/mc1.png",
      "images/mc2.png",
      "images/mc3.png",
    ],
    desc: [
      "Event hosted for learning Git,Github and React",
      "My Microsoft Swag",
      "My Microsoft Swag",
      "My Microsoft Swag",
    ],
  },
  {
    id: 2,
    title: "Web Development Tutor For Kids",
    image: "images/webdev.gif",
    content1:
      "This non-profit organization aims to aid underserved children aged 12 to 15 by exposing them to web programming at a young age.",
    content2:
      "In my spare time, I frequently volunteer to assist young children in my community by exposing them to the power of technology. I currently have four students who I tutor privately and assist in their web development journey.",
    images: [
      "images/shane1.png",
      "images/Zchanea.png",
      "images/Carlos.png",
      "images/Shane2.png",
    ],
    link: "",
    desc: [
      "Shane's(12) box model practice",
      "Zchanea's(12) Box model practice",
      "Carlos'(13) Box model practice",
      "Shane's(12) Amazing final project",
    ],
  },

  // download.jfif
  // {
  //   id: 3,
  //   title: "3 Things To Know About Startup Business",
  //   image: "images/blog/3.svg",
  //   filesource: "../../blogs/things-to-know-about-startup-business.md",
  //   date: "06 February, 2020",
  //   author: "Bolby",
  //   category: "Business",
  // },
];

function Blogs() {
  return (
    <ScrollAnimation
      animateIn="slideInRight"
      animateOut="slideInLeft"
      // animateOnce={true}
    >
      <section id="blog" style={{ position: "relative" }}>
        <div className="container">
          <Pagetitle
            title="Leadership and Organizations"
            image="/images/com4-gif-maker-unscreen.gif"
          />
          <div className="row blog-wrapper middle2 text-center">
            {allBlogs.map((blogItem) => (
              <div className="col-md-4 m-3 heights1" key={blogItem.id}>
                <Blog blogData={blogItem} />
              </div>
            ))}
          </div>
          {/* <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/blogs" className="btn btn-default">
            Show all blogs
          </Link>
        </div> */}
        </div>
      </section>
    </ScrollAnimation>
  );
}

export default Blogs;
