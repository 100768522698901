import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "./App.css";

function Pagetitle({ title, image }) {
  return (
    <>
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <h2 className="section-title servicetitle">{title}</h2>
        {image ? (
          <img
            className="imageservicetitle"
            style={{ height: "80px" }}
            src={image}
          />
        ) : (
          <></>
        )}
      </ScrollAnimation>
      <div className="spacer" data-height="60"></div>
    </>
  );
}

export default Pagetitle;
