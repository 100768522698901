import React from "react";
import Works from "./MySoftwareWorks";
function sectionworks(props) {
  return (
    // <div style={{ background: "#353d43" }}>
    <Works />
    // </div>
  );
}

export default sectionworks;
