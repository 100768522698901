import { ReactComponent as WorkIcon } from "../svgFolder/work.svg";
import { ReactComponent as SchoolIcon } from "../svgFolder/school.svg";
import Pagetitle from "../elements/Pagetitle";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

import timelineElements, { description } from "./timelineElements";
import React, { useState } from "react";
import "./App.css";
import IMG from "./n.jpg";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Row";

function NewTimeline() {
  const [modalState, setModalState] = useState("");
  const [images, setImages] = useState([]);

  let workIconStyles = { background: "#66fcf1 " };
  let schoolIconStyles = { background: "#f9c74f" };
  const [desc, setDesc] = useState([]);
  const [role, setRole] = useState("");
  const [title, setTitle] = useState("");
  const [imgdesc, setImgDesc] = useState([]);

  const handleShowModalOne = (g, img, role, title, imgdes) => {
    setModalState("modal-one");
    setDesc(g);
    setImages(img);
    setRole(role);
    setTitle(title);
    setImgDesc(imgdes);
  };

  const handleShowModalTwo = () => {
    setModalState("modal-two");
  };

  const handleClose = () => {
    setModalState(false);
  };

  return (
    <section id="workexperience">
      {/* <h1 className="title">Timeline</h1> */}

      <div className="container">
        <Pagetitle
          title="Work History"
          image="/images/com6-gif-maker-unscreen.gif"
        />
        <VerticalTimeline className="edu  rounded shadow-dark padding-30 overflow-hidden ">
          {timelineElements.map((element) => {
            let isWorkIcon = element.icon === "work";
            let showButton =
              element.buttonText !== undefined &&
              element.buttonText !== null &&
              element.buttonText !== "";

            return (
              <VerticalTimelineElement
                key={element.key}
                date={element.date}
                dateClassName="date"
                iconStyle={isWorkIcon ? workIconStyles : workIconStyles}
                icon={isWorkIcon ? <WorkIcon /> : <WorkIcon />}
              >
                <div className="text-center  ">
                  <h2
                    className="vertical-timeline-element-title 
                   
                  "
                  >
                    <em> {element.title} </em>
                  </h2>

                  <div className="g mt-3">
                    <p className="vertical-timeline-element-subtitle  rounded  ">
                      <strong>
                        {" "}
                        <i> {element.company}</i>{" "}
                      </strong>
                    </p>
                    <p className="  ">
                      <u>Location: {element.location}</u>
                    </p>
                  </div>

                  <Button
                    onClick={() =>
                      handleShowModalOne(
                        element.description,
                        element.images,
                        element.role,
                        element.title,
                        element.desc
                      )
                    }
                    variant="dark"
                    className="mt-3"
                  >
                    View More Details
                  </Button>
                </div>
              </VerticalTimelineElement>
            );
          })}

          <Modal
            size="lg"
            className="modalcontainer"
            show={modalState == "modal-one"}
            onHide={handleClose}
          >
            <Modal.Header className="bg-white middle2" closeButton>
              <Modal.Title
                className=" rounded shadow-dark px-3 overflow-hidde"
                style={{ backgroundColor: "#66fcf1" }}
              >
                {" "}
                {title}
              </Modal.Title>
            </Modal.Header>

            {/* {timelineElements.map((element) => { */}

            {images ? (
              <div>
                <Carousel>
                  {images &&
                    images.map((img, index) => {
                      return (
                        <Carousel.Item interval={2000}>
                          <div onClick={handleShowModalTwo}>
                            <img
                              className="d-block w-100"
                              src={img}
                              alt="First slide"
                            />
                          </div>

                          <Carousel.Caption style={{ color: "black" }}>
                            {/* <h3 style={{ color: "black" }}>First slide label</h3> */}
                            <p
                              style={{
                                color: "rgb(102, 252, 241)",
                                fontFamily: "Helvet",
                                fontSize: 25,
                                fontWeight: "bold",
                                backgroundColor: "#fffff0",
                                borderRadius: 40,
                              }}
                            >
                              {imgdesc[index]}
                            </p>
                          </Carousel.Caption>
                        </Carousel.Item>
                      );
                    })}
                </Carousel>
              </div>
            ) : (
              <div></div>
            )}

            <div className=" container bg-white px-5">
              <ul>
                {desc &&
                  desc.map((descr) => {
                    return (
                      <li className="my-3" key={descr}>
                        {descr}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </Modal>
        </VerticalTimeline>
      </div>
    </section>
  );
}

export default NewTimeline;
