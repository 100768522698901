import React, { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import ScrollAnimation from "react-animate-on-scroll";
import Portfolio from "../elements/Portfolio";
import Select from "react-select";
import "./styles.css";
import SwitchSelector from "react-switch-selector";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CanvasJSReact from "./assets/canvasjs.react.js";
import "./modal.css";
import ReactTooltip from "react-tooltip";
import "../../App.scss";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
//lowercase the first letter to have category enabled
const filters = [
  {
    id: 1,
    text: "Everything",
  },
  {
    id: 2,
    text: "Front End",
  },
  {
    id: 3,
    text: "Full Stack",
  },

  {
    id: 4,
    text: "Artificial Intelligence",
  },
  // {
  //   id: 4,
  //   text: "system architecting",
  // },
];

const allData = [
  {
    id: 1,
    title: "Mobby Resto",
    category: "Full Stack",
    image: "images/mobby.GIF",
    group: true,
    images: [
      "https://www.youtube.com/embed/w6hqlh2p5W8",
      "images/1mb.png",
      "images/2mb.png",
      "images/3mb.png",
      "images/4mb.png",
      "images/5mb.png",
      "images/6mb.png",
      "images/7mb.png",
      "images/8mb.png",
      "images/9mb.png",
      "images/10mb.png",
      "images/11mb.png",
    ],
    content:
      "Mobby Resto is a mobile application based on the concept of discovering the best restaurants in a particular location; similar to Yelp, but with built-in features such as Google Maps, a direct phone button, a random restaurant picker, food categorization, location changer, rating sorter, and the ability to create favorites.",
    task:
      "In this group project, I was the lead developer for front-end features and back-end routes. The front end uses an auth token to retrieve data from the Yelp API, and then we use back-end routes to perform CRUD operations on the database. Authentication was also implemented, allowing users to log in, thus providing session-based authentication.",
    technology: ["React Native", "Redux", "C#", "API", "Mobile App"],
    demolink: "",
    // ytlink: "https://www.youtube.com/embed/CEi0vWLPcDs",
    codesource: "https://github.com/jtagaca/Mobby_Resto",
  },
  {
    id: 115,
    title: "Get To Know Me More",
    category: "Artificial Intelligence",
    image: "http://jtagaca.live/images/gettoknowmemore.gif",
    images: [
      "http://jtagaca.live/images/gettoknowmemore1.png",
      "http://jtagaca.live/images/gettoknowmemore2.png",
      "http://jtagaca.live/images/gettoknowmemore3.png",
    ],
    content:
      "Get To Know Me More is an AI-powered app that allows you to ask an AI questions about me to learn more about my personality and professional history. The AI will then respond with relevant information.",
    task:
      "In this project, Alan AI is being used to answer questions about myself. Additionally the AI can respond by incorporating pre-programmed responses to a variety of topics. Furthermore, once the AI sends the current question it believes the user has asked, it opens a modal to help as a visual aid to assist the user in understanding the AI's answer. The Front End of this project is built with React, and the app is deployed using GitHub pages.",
    technology: ["React.JS", "Redux", "API", "AI"],
    codesource: "https://github.com/jtagaca/GetToKnowMeMoreApp",
    demolink: "https://gettoknowmemore.jtagaca.live",
  },
  {
    id: 8,
    title: "Movie Finder",
    category: "Front End",
    image: "images/moviefinder.GIF",
    images: [
      "images/1mv.png",
      "images/2mv.png",
      "images/3mv.png",
      "images/4mv.png",
    ],
    content:
      "Movie finder is a web-based application that enables users to look up information on a particular film.",
    task:
      "This was a personal project that was built with React.JS and hosted on Heroku. To supply data for the searched movies, the program makes use of an API from tMDB, also known as The Movie Database. The program allows users to find a movie, watch a trailer, examine the movie poster, and find the actors/actresses of the film that they have chosen. Users will also be able to check the ratings and summary of the chosen film.",
    technology: ["React.JS", "Redux", "API", "Web App", "Heroku"],
    demolink: "https://moviefinder.jtagaca.live/",
    codesource: "https://github.com/jtagaca/moviesAPIProject",
  },
  {
    id: 113,
    title: "Java FX Todo",
    category: "Full Stack",
    image: "http://jtagaca.live/images/JavaFXTodo.gif",
    images: [
      "http://jtagaca.live/images/JavaFX1.png",
      "http://jtagaca.live/images/JavaFX2.png",
      "http://jtagaca.live/images/JavaFX3.png",
    ],
    content:
      "Java FX My Todo app is a Java based application that allows users to create, edit, and delete tasks.",
    task:
      "This full-stack project includes a firebase backend and a Java Front End. This application was created for desktop computers. Additionally, users can easily manage task lists.",
    challenge:
      "Because I was unfamiliar with firebase and had no prior experience performing NoSQL operations on firebase, this project presented challenges. However, I discovered that NoSQL is best suited for simple applications that do not require atomic transactions or schema-less tables. I completed this project in one week despite having only one month of Java experience.",
    technology: ["Java", "JavaFX", "API", "NoSQL"],
    codesource: "https://github.com/jtagaca/JavaFXTodoApp",
    demolink: "",
  },

  {
    id: 7,
    title: "AI news",
    category: "Front End",
    image: "images/ainews.GIF",
    images: ["images/1ai.png", "images/2ai.png", "images/3ai.png"],
    content:
      "AI News is an Artificial Intelligence-based application that enables users to converse verbally and search for news information.",
    task:
      "This was a personal project built with React.JS and deployed using Heroku. The application makes use of an API called Alan AI, which enables the creation of predefined capabilities for voice recognition. Additionally, the program makes use of the bootstrap and material design libraries to allow quicker UI development. The challenge with this application was that it was my first AI-based online application, and thus I needed to study up on the fundamentals of implementing certain functionalities. I primarily learned it via viewing online tutorials and forums, as well as by reading the Alan Ai documentation.",
    technology: ["React.JS", "Redux", "API", "Web App", "Heroku"],
    demolink: "https://ainews.jtagaca.live/",
    codesource: "https://github.com/jtagaca/newsAIproject",
  },

  {
    id: 119,
    title: "AI Salary Predictor",
    category: "Artificial Intelligence",
    image: "http://imageserver.jtagaca.live/ai_salary_predictor.png",
    images: [
      "http://imageserver.jtagaca.live/ai_salary_predictor.png",
      "http://imageserver.jtagaca.live/ai_salary_predictor2.png",
      "http://imageserver.jtagaca.live/ai_salary_predictor3.png",
    ],
    content:
      "AI Salary Predictor is an AI model that can predict employee salaries based on their years of experience.",
    task:
      "This AI model makes use of a data set that includes two fields: years of experience and salary. Years of experience is an independent variable, whereas salary is dependent on years of experience. This demonstrates that the more employee experience someone has, the higher their salary will be. To create the model, we split the data set and then created a linear regression model to predict the salary. In addition, we run a polynomial regression model on the data to better predict the salary.",
    technology: ["Python", "Linear Regression", "Polynomial Regression", "AI"],
    codesource: "https://github.com/jtagaca/ai_salary_predictor",
    demolink: "https://salarymodelpredictor.jtagaca.live",
  },

  {
    id: 117,
    title: "AI Python Chat",
    category: "Artificial Intelligence",
    image: "http://imageserver.jtagaca.live/ai_python_chat1.gif",
    images: [
      "http://imageserver.jtagaca.live/ai_python_chat1.gif",
      "http://imageserver.jtagaca.live/ai_python_chat2.gif.png",
    ],
    content:
      "AI chat is a Python based bot that allows you to easily create your own bot by changing the values of a JSON.",
    task:
      "This bot reads a JSON file and checks to see if the user input's word is in the JSON file, and if it is, it returns a predefined response. This simple bot can be used to create your own. Additionally, this shows how a simple program can save manual labor, particularly in customer service roles.",
    technology: ["Python", "AI"],
    codesource: "https://github.com/jtagaca/ai_python_chat",
  },

  {
    id: 116,
    title: "Genetic AI",
    category: "Artificial Intelligence",
    image: "http://jtagaca.live/images/Genetic_AI2.png",
    images: [
      "http://jtagaca.live/images/Genetic_AI3.gif",
      "http://jtagaca.live/images/Genetic_AI2.png",
      "http://jtagaca.live/images/Genetic_AI.png",
    ],
    content:
      "Genetic AI was a lab assignment in my Artificial Intelligence class that demonstrated how AI can solve problems by learning from its mistakes.",
    task:
      "This python-based genetic AI algorithm aims to produce a fitness level of 32, which indicates that all the bits in a single offspring are 1. The code output shows that for every generation, the AI uses probability and luck to achieve its end goal of producing one offspring with all bits set to 1.",
    technology: ["Python", "AI", "Genetic Programming"],
    codesource: "https://github.com/jtagaca/GeneticsAI",
  },
  {
    id: 4,
    title: "PathFinder",
    category: "Front End",
    image: "images/pathfinder.GIF",
    images: [
      "images/1pf.png",
      "images/2pf.png",
      "images/3pf.png",
      "images/4pf.png",
      "images/5pf.png",
    ],
    task:
      "This was a personal project built with React.JS and deployed using Github Pages. The application enables users to visualize the path between two nodes; additionally, they can create an obstacle or wall to see whether the path remains feasible.",
    content:
      "PathFinder is a graph visualizer that illustrates the process of determining the shortest path. ",
    technology: ["React.JS", "API", "Web App"],
    demolink: "https://pathfinder.jtagaca.live/",
    codesource: "https://github.com/jtagaca/GraphVisualization",
  },

  {
    id: 118,
    title: "AI Car Learning",
    category: "Artificial Intelligence",
    image: "http://imageserver.jtagaca.live/ai_car.gif",
    images: [
      "http://imageserver.jtagaca.live/ai_car.gif",
      "http://imageserver.jtagaca.live/ai_car_2.png",
      "http://imageserver.jtagaca.live/ai_car_3.png",
    ],
    content:
      "AI Python self car learner demonstrates an AI car learner that can gradually learn to drive a race track.",
    task:
      "This AI self-learning car allows it to learn by recording if it could produce an offspring that was able to pass the race track. It knows that this generation of cars succeeded by checking if any number greater than 0 has been able to cycle the entire track. The cars also learn from their mistakes while moving on the race track using their sensors. The new generation then creates a new type of car that can learn from the previous generation's mistakes. For the cars to display the UI pygame was used, neural networks are then utilized for the cars to learn to drive.",
    technology: ["Python", "AI", "Neural Networks", "PyGame"],
    codesource: "https://github.com/jtagaca/ai_learning_car",
  },

  {
    id: 6,
    title: "Sorting Visualizer",
    category: "Front End",
    image: "images/4sort.png",
    images: [
      "images/1sort.png",
      "images/2sort.png",
      "images/3sort.png",
      "images/4sort.png",
    ],
    content:
      "Sorting is a web-based application that allows users to visualize the most common sorting algorithm in computer science. ",
    task:
      "This was a personal project built with React.JS and deployed using Github Pages. The application allows users to visualize sorting algorithms and perform additional actions such as being able to choose the speed of the sorting and have a button for sounds.  This project was tough to build, but with many youtube tutorials and learning, it can be done!",
    technology: ["React.JS", "Redux", "API", "Web App", "AWS"],
    demolink: "https://sorting.jtagaca.live/",
    codesource: "https://github.com/jtagaca/GraphVisualization",
  },
  {
    id: 5,
    title: "SnakeV2",
    category: "Front End",
    image: "images/snake.GIF",
    images: [
      "images/1nk.png",
      "images/2nk.png",
      "images/3nk.png",
      "images/4nk.png",
    ],
    task:
      "This was a personal project built with React.JS and hosted on Github Pages.This project was inspired by a snake game I created during my freshman year of college. I wanted to demonstrate that everything can be better through time.",
    content:
      "snakev2 is a modernized version of snake in which the user can play until the snake's tail is reached or touched. The snake will continue to increase in size as it consumes candy food",
    technology: ["React.JS", "Redux", "Web App"],
    demolink: "https://snakev2.jtagaca.live/",
    codesource: "https://github.com/jtagaca/snakev2",
  },
  {
    id: 14,
    title: "Wildfire",
    category: "Front End",
    image: "images/wildfire.GIF",
    images: [
      "images/1wf.png",
      "images/2wf.png",
      "images/3wf.png",
      "images/4wf.png",
    ],
    task:
      " This was a personal project built with React.JS and deployed via AWS amplify. The application takes data from a NASA API to provide information on the location of wildfires and then visualizes the geographical map using the Google Maps javascript API.",
    challenge: "",
    content:
      "Wildfire is a wildfire tracker that allows users to obtain information about the most current global wildfires. This allows users to be more aware of the recent wildfires.",
    technology: ["React.JS", "Redux", "API", "Web App", "AWS"],
    codesource: "https://github.com/jtagaca/wildfire_V1",
  },
  {
    id: 9,
    title: "Stock Finder",
    category: "Front End",
    image: "images/5st.png",
    images: [
      "images/1st.png",
      "images/3st.png",
      "images/4st.png",
      "images/2st.png",
      "images/5st.png",
    ],
    content:
      "Stock Finder is a web-based application that allows users to find the current price of a stock in real time.",
    task:
      "This was a self-developed project utilizing React.js and deployed using AWS amplify. The application uses the finhub API to obtain information about the stock's current market price being searched. The application's capabilities include finding the current stock price in real-time and providing suggestions based on the user's input.",
    technology: ["React.JS", "Redux", "API", "Web App", "Heroku"],
    demolink: "https://stockfinder.jtagaca.live/",
    codesource: "https://github.com/jtagaca/stocktrader",
  },

  // {
  //   id: 10,
  //   title: "AWS Architecting",
  //   category: "Front End",
  //   image: "images/1ar.png",
  //   images: [
  //     "images/1ar.png",
  //     "images/2ar.png",
  //     "images/3ar.png",
  //     "images/4ar.png",
  //     "images/5ar.png",
  //     "images/7ar.png",
  //   ],

  //   //TODO needs title and to clarify
  //   architecttitle: [
  //     "test1",
  //     "architecttitle",
  //     "architecttitle",
  //     "architecttitle",
  //     "architecttitle",
  //     "architecttitle",
  //   ],
  // },
];
function MySoftwareWorks() {
  const [getAllItems] = useState(allData);
  const [currentCount, setCurrentCount] = useState(0);
  const [dataVisibleCount, setDataVisibleCount] = useState(12);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);
  const [isBeingFiltered, setisBeingFiltered] = useState(false);
  const [optionsData, setOptions] = useState([]);
  const [currentSelectionState, setCurrentSelectionState] = useState(
    "bycategory"
  );
  const [currentTechnologies, setCurrentTechnologies] = useState([]);

  const options_switch = [
    {
      label: (
        <div className="middle2">
          <p
            style={{
              margin: "20px",
              fontWeight: 700,
              paddingTop: 10,
              textAlign: "center",
              fontSize: 20,
              color: "white",
            }}
          >
            By Category
          </p>
        </div>
      ),
      value: "bycategory",
      selectedBackgroundColor: "#66fcf1",
    },
    {
      label: (
        <div lassName="middle2">
          <p
            style={{
              margin: "20px",
              fontWeight: 700,
              paddingTop: 10,
              textAlign: "center",
              fontSize: 20,
              color: "white",
            }}
          >
            By Technology
          </p>
        </div>
      ),
      value: "bytechnology",
      selectedBackgroundColor: "#66fcf1",
    },
  ];
  const onChange = (newValue) => {
    setCurrentSelectionState(newValue);
    getDataAnalytics(newValue);
  };

  const initialSelectedIndex = options_switch.findIndex(
    ({ value }) => value === "bycategory"
  );
  var total = 0;
  var labels = [];
  const options = {
    theme: "dark2",
    animationEnabled: true,
    title: { text: "" },
    axisX: {
      interval: 1,
      labelAngle: -70,
    },

    data:
      currentSelectionState == "bycategory"
        ? [
            {
              type: "pie",
              startAngle: 75,
              toolTipContent: "<b>{label}</b>: {y} (#percent%)",

              percentFormatString: "#0.##",
              indexLabelFormatter: function(e) {
                total = e.total;
                var label =
                  e.dataPoint.label +
                  ": " +
                  e.dataPoint.y +
                  "/" +
                  e.total +
                  " " +
                  "(" +
                  ((e.dataPoint.y / total) * 100).toFixed(2) +
                  "%)";
                return label;
              },
              showInLegend: "true",
              legendText: "{label}",
              indexLabelFontSize: 13,
              indexLabel: "{label} - #percent%",
              indexLabelPlacement: "inside",
              dataPoints: optionsData,
            },
          ]
        : [
            {
              // axisY: {
              //   interval: 1,
              type: "column",
              indexLabel: "{y}",
              indexLabelPlacement: "outside",
              indexLabelOrientation: "horizontal",
              dataPoints: optionsData,
            },
          ],
  };

  const [technologies, setTechnologies] = useState([]);
  const [prevState, setPrevState] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getTechnologies() {
    // Implement
    // for each visibleItems create a set that has the technology property

    let temp = new Set();
    for (let i = 0; i < visibleItems.length; i++) {
      for (let j = 0; j < visibleItems[i].technology.length; j++) {
        temp.add(visibleItems[i].technology[j]);
      }
    }
    // why am I not able to set the state?
    let tempArray = [...temp];
    let newArr = [];
    // for each value in tempArray we create a new object with the field of label and value
    for (let i = 0; i < tempArray.length; i++) {
      newArr.push({ label: tempArray[i], value: tempArray[i] });
    }
    setTechnologies(newArr);
  }
  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    // we first set to a limit of 12 but the max would be 18 overall
    let tempArr = [];
    let i = 0;
    while (i < dataVisibleCount) {
      tempArr.push(allData[i]);
      i++;
    }
    setCurrentCount(i);

    setVisibleItems(tempArr);
  }, []);
  useEffect(() => {
    // not able to set technologies?
    // why is technologies empty?
    // even after watching visibleItems?
    getTechnologies();

    // console.log(visibleItems);
  }, [visibleItems]);

  function handleFilterBySearch(e) {
    // Implement

    if (e == null || e.length == 0) {
      setVisibleItems(prevState);
      return;
    }
    let temp = new Set();
    // for each value in e we check each of the visibleItems and if the value is in the technology array we add it to a tempArr

    for (let j = 0; j < visibleItems.length; j++) {
      let count = 0;

      // for each e we check if we contain all the technologies in this visibleItems
      for (let i = 0; i < e.length; i++) {
        if (visibleItems[j].technology.includes(e[i].value)) {
          count++;
        }
      }
      if (count == e.length) {
        temp.add(visibleItems[j]);
      }
    }
    // for each val in the e then we add to the current technologies

    setCurrentTechnologies(e);

    setVisibleItems([...temp]);
  }
  // useEffeect that will be watching for dataVisibleCount and we use the current count for idx on the allData array
  // we need to copy the visible items into a temp array and keep appending there
  useEffect(() => {
    let tempArr = [...visibleItems];
    let i = currentCount;
    while (i < dataVisibleCount) {
      tempArr.push(allData[i]);
      i++;
    }
    setCurrentCount(i);
    setVisibleItems(tempArr);
  }, [dataVisibleCount]);
  const handleLoadmore = (e) => {
    e.preventDefault();
    setNoMorePost(true);
    setDataVisibleCount(allData.length);
  };
  const handleChange = (e) => {
    //  ;
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData = [];
    if (
      e.target.textContent.toLowerCase() === filters[0].text.toLowerCase() &&
      noMorePost == false
    ) {
      let i = 0;
      while (i < dataVisibleCount) {
        tempData.push(allData[i]);
        i++;
      }
    } else if (
      e.target.textContent.toLowerCase() === filters[0].text.toLowerCase() &&
      noMorePost == true
    ) {
      tempData = allData;
    } else {
      //  ;
      tempData = allData.filter(
        (data) =>
          data.category.toLowerCase() === e.target.textContent.toLowerCase()
      );
    }
    setVisibleItems(tempData);
  };
  function getDataAnalytics(currrentlySelected) {
    // Implement
    // for item in visibleItems
    // create a map
    if (currrentlySelected == "bycategory") {
      let map = new Map();
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].text.toLowerCase() == "everything") {
          continue;
        }
        map.set(filters[i].text, 0);
      }
      for (let j = 0; j < visibleItems.length; j++) {
        // for each e we check if we contain all the technologies in this visibleItems
        let count = map.get(visibleItems[j].category) + 1;
        map.set(visibleItems[j].category, count);
      }
      // for val in map
      let tempArr = [];
      map.forEach((val, key) => {
        if (val != 0) {
          tempArr.push({
            y: val,
            label: key,
          });
        }
      });

      setOptions(tempArr);
      return;
    } else {
      let map = new Map();
      for (let i = 0; i < technologies.length; i++) {
        map.set(technologies[i].label, 0);
      }
      for (let j = 0; j < visibleItems.length; j++) {
        // for each e we check if we contain all the technologies in this visibleItems
        // let count = map.get(visibleItems[j].);
        // for each technology in the
        for (let k = 0; k < visibleItems[j].technology.length; k++) {
          // for each technology in the visibleItems
          let count = map.get(visibleItems[j].technology[k]) + 1;
          map.set(visibleItems[j].technology[k], count);
        }
      }
      // for val in map
      let tempArr = [];
      map.forEach((val, key) => {
        if (val != 0) {
          tempArr.push({
            y: val,
            label: key,
          });
        }
      });
      setOptions(tempArr);
      return;
    }
  }

  function saveValue() {
    // Implement
    setPrevState(visibleItems);
  }
  const [modalState, setModalState] = useState(false);
  useEffect(() => {
    setCurrentSelectionState("bycategory");
    getDataAnalytics("bycategory");
  }, [modalState]);

  const handleClose = () => {
    setModalState(false);
  };
  const [isHovering, setIsHovering] = useState(false);
  return (
    <>
      <div className="mb-5">
        <Modal
          size="xxl-down"
          className="modal-defer"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          show={modalState}
          onHide={handleClose}
        >
          <Modal.Header className="bg-white" closeButton>
            <SwitchSelector
              onChange={onChange}
              options={options_switch}
              initialSelectedIndex={initialSelectedIndex}
              backgroundColor={"black"}
              fontColor={"#f5f6fa"}
              fontSize={"20px"}
              centered
            />
          </Modal.Header>
          <Modal.Body
            className="mymodal-content middle2"
            style={{
              width: "100%",
            }}
          >
            <CanvasJSChart
              options={options}
              containerProps={{
                width: "600px",
                height: "600px",
              }}
            />
          </Modal.Body>
        </Modal>{" "}
        <ScrollAnimation
          animateIn="bounceInLeft"
          animateOut="bounceOut"
          afterAnimatedIn={() => {
            alert(
              "Projects with Live Demonstrations Links may take up to a minute to load.\n\nThis is because the server must sleep while it is not in use in order for the application to be in the free tier.\n\nPLEASE ACCEPT MY APOLOGIES FOR THE INCONVENIENCE, AND THANK YOU FOR YOUR PATIENCE 😊"
            );
          }}
          animateOnce={true}
        >
          <div className="container">
            <h1 className="mytitle mb-5">Projects</h1>
            {/* Stautomation Portfolio Filters */}
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut">
              <div className="row bringFront mb-3">
                <div className="col-md-8 col-xs-4">
                  <div className="row">
                  <ul className="portfolio-filter list-inline">
                    {filters.map((filter) => (
                      <li
                        data-tip
                        data-for={filter.text}
                        className="list-inline-item"
                        key={filter.id}
                      >
                        <button
                          onClick={handleChange}
                          className={
                            filter.text.toLowerCase() === activeFilter
                              ? "text-capitalize current"
                              : "text-capitalize"
                          }
                          disabled={
                            filter.text.toLowerCase() !=
                              activeFilter.toLowerCase() && isBeingFiltered
                          }
                        >
                          {filter.text}
                        </button>
                        {filter.text.toLowerCase() !=
                          activeFilter.toLowerCase() && isBeingFiltered ? (
                          <ReactTooltip id={filter.text} role="example">
                            <p>
                              Please Close, Clear and Remove Cursor Input on
                              Filter By Technology
                            </p>
                          </ReactTooltip>
                        ) : null}
                      </li>
                    ))}
                  
                  </ul>
                  <Button
                      data-tip
                      data-for="global"
                      className=" rainbow2 ti-btn2 analytics-button-2"
                      onMouseEnter={() => setIsHovering(true)}
                      onMouseLeave={() => setIsHovering(false)}
                      onClick={() => {
                        setModalState(true);
                      }}
                      variant="dark"
                    >
                      <div style={{ fontWeight: 700 }}>
                        {visibleItems.length}
                        <FaInfoCircle className="ml-1 mb-1" />
                      </div>
                    </Button>
                  {isHovering ? (
                    <ReactTooltip id="global" role="example">
                      <p>Click To Perform Data Analytics</p>
                    </ReactTooltip>
                  ) : null}
                </div>
                </div>
                <div className="col-md-4 ">
                  <Select
                    options={technologies}
                    menuPlacement="top"
                    // hasValue={}
                    isMulti
                    className="basic-multi-select bringFront"
                    classNamePrefix="select technologies"
                    onMenuClose={() => {
                      if (currentTechnologies.length == 0) {
                        setisBeingFiltered(false);
                      }
                    }}
                    onMenuOpen={() => {
                      setisBeingFiltered(true);
                      saveValue();
                    }}
                    closeMenuOnSelect={false}
                    placeholder="Filter by Technology"
                    onChange={(e) => {
                      if (e == null || e.length == 0) {
                        setCurrentTechnologies([]);
                      }
                      setisBeingFiltered(true);
                      handleFilterBySearch(e);
                    }}
                  />
                </div>
              </div>
            </ScrollAnimation>
            {/* End Portfolio Filters */}
            {/* Stautomation Portfolio Items */}
            <div className="row portfolio-wrapper bringBack">
              {visibleItems.map((item) => (
                <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
                  <Portfolio portfolio={item} />
                </div>
              ))}
            </div>
            {/* End Portfolio Items */}
            {activeFilter == "everything" && isBeingFiltered == false ? (
              <div className="load-more text-center mt-4">
                <Button
                  className=" mb-5"
                  variant="info"
                  onClick={handleLoadmore}
                  disabled={noMorePost ? "disabled" : null}
                >
                  {noMorePost ? (
                    "No more items"
                  ) : (
                    <span>
                      <i className="fas fa-spinner"></i> Load more
                    </span>
                  )}
                </Button>
              </div>
            ) : null}
          </div>
        </ScrollAnimation>
      </div>
    </>
  );
}

export default MySoftwareWorks;
