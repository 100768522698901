import React, { useState } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
const config = {
  width: "400px",
  height: "500px",
  floating: true,
};

const theme = {
  background: "#353d43",
  fontFamily: "sans-serif",
  fontWeight: "bold",
  headerBgColor: "#66fcf1",
  headerFontColor: "#353d43",
  headerFontSize: "25px",
  botBubbleColor: "#66fcf1",
  botFontColor: "black",
  userBubbleColor: "#66fcf1",
  userFontColor: "black",
  textAlign: "center",
};

function SimpleForm() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  function toggleFloating({ opened }) {
    setIsChatOpen(opened);
  }
  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        opened={isChatOpen}
        recognitionEnable={true}
        steps={[
          {
            id: "intro",
            message: "Do you want to learn more about James?",
            trigger: "intro-user",
          },
          {
            id: "intro-user",
            options: [
              { value: "y", label: "Yes", trigger: "yes-response" },
              { value: "n", label: "No", trigger: "no-response" },
            ],
          },
          {
            id: "yes-response",
            message:
              "Great Choice!, Choose an option below and we can get started!",
            trigger: "what-is-your-question",
          },
          {
            id: "what-is-your-question",
            options: [
              {
                value: "h",
                label: "What are the Characteristics of James?",
                trigger: "character",
              },
              {
                value: "g",
                label: "What is his favorite programming languange?",
                trigger: "fav-programming",
              },
              {
                value: "j",
                label: "When did James start coding?",
                trigger: "start-coding",
              },
              {
                value: "l",
                label: "What is the most important thing to James?",
                trigger: "most-imp",
              },
              {
                value: "o",
                label: "Learn about James using your voice instead",
                trigger: "gettoknow",
              },
              {
                value: "q",
                label: "End Chat",
                trigger: "end-chat",
              },
            ],
          },
          {
            id: "gettoknow",
            message:
              "Opening a new tab for you! Remember to use your voice this time ok!",
            trigger: () => {
              window.open("http://gettoknowmemore.jtagaca.live/");
              return "askmore";
            },
          },
          {
            id: "askmore",
            message: "Do you want to learn more?",
            trigger: "askmore_y_n",
          },
          {
            id: "askmore_y_n",
            options: [
              { value: "y", label: "Yes", trigger: "what-is-your-question" },
              { value: "n", label: "No", trigger: "end-chat" },
            ],
          },
          {
            id: "character",
            message:
              "James is considered Driven, Persistent, Leader, an Entrepreneur, and a Problem Solver",
            trigger: "askmore",
          },
          {
            id: "fav-programming",
            message: "Python!",
            trigger: "askmore",
          },
          {
            id: "most-imp",
            message: "Family",
            trigger: "askmore",
          },
          {
            id: "start-coding",
            message: "Freshman year of 2019",
            trigger: "askmore",
          },
          {
            id: "no-response",
            message:
              "I see no worries! Just a heads up he is really a great person!",
            trigger: () => {
              toggleFloating({ opened: false });
              return "restart";
            },
          },
          {
            id: "end-chat",
            message:
              "Thanks for learning about James! Reach out, pretty sure he will welcome it!",
            trigger: () => {
              toggleFloating({ opened: false });
              return "restart";
            },
            // end: true,
          },
          {
            id: "restart",
            message: "Do you want to go again?",
            trigger: "askmore_y_n",
          },
        ]}
        {...config}
        toggleFloating={toggleFloating}
      />
    </ThemeProvider>
  );
}

export default SimpleForm;
