import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";
import ScrollAnimation from "react-animate-on-scroll";
const servicesData = [
  {
    id: 1,
    icon: "images/icons8-web-96.png",
    title: "Automation           ",
    content:
      "I enjoy automating repetitive tasks that are deemed time-consuming to complete. Python and shell scripting are my primary tools for automating tasks.",
    color: "#fffff0",
    contentColor: "dark",
    // symbol: "💡",
  },
  //TODO fix the first box size
  {
    id: 2,
    title: "Software Engineering",
    icon: "images/code.png",
    content:
      "I am experienced in Fullstack Development, having worked with the MERN and Laravel frameworks, and hold an industry-recognized credential.",
    color: "#fffff0",
    // symbol: "💻",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/icons8-cloud-link-96.png",
    title: "System and Cloud Architecture",
    content:
      "At the present, I hold three industry-recognized certifications focused on cloud and system architecture.",
    color: "#fffff0",
    contentColor: "dark",
  },
  {
    id: 4,
    icon: "images/icons8-website-analytics-96.png",
    title: "Data Analytics and Artificial Intelligence",
    content:
      "I am experienced in performing Data Analysis and creating Data Visualizations to help clients in answering business-related questions.",
    color: "#fffff0",
    contentColor: "dark",
  },
];
function Services() {
  return (
    <ScrollAnimation animateIn="bounceInRight" animateOut="bounceOutLeft">
      <section id="services">
        <div className="container">
          <Pagetitle title="Specializations" image="/images/mygif.gif" />

          <div className="row fix-spacing">
            {servicesData.map((service) => (
              <div className="col-md-3" key={service.id}>
                <Service service={service} />
              </div>
            ))}
          </div>
          <div className="mt-5 text-center"></div>
        </div>
      </section>
    </ScrollAnimation>
  );
}
export default Services;
