import React from "react";
import Works from "./Certifications";

function sectionCert(props) {
  return (
    <div>
      <Works />
    </div>
  );
}

export default sectionCert;
