import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Pricebox from "../elements/Pricebox";
import Carousel from "react-elastic-carousel";
import ScrollAnimation from "react-animate-on-scroll";

// using ↓ to pass information to price component
const priceboxData = [
  {
    id: 0,
    title: "Software Developer",
    image: "images/ibmace.png",
    content:
      "This credential showcases a deep understanding and skills in the following programming competencies: data structures & algorithms, front end & back end development, application security, and project management. The individual has also gained a great understanding of coding assessment platforms, GitHub, the software development lifecycle, and coding logic.",
    issuer: "IBM",
    Moreinfo: "https://ibm.ent.box.com/s/old8vn2luhjmgixmwilkktpuxxd92uki",
    QuickVeri:
      "https://www.credly.com/badges/8519f7aa-c995-4503-8f9a-a79be608cb1f",
  },

  {
    id: 2,
    title: "Solutions Architect-Associate",
    image: "images/awsa.jpg",
    content:
      "This AWS Associate certification is intended for individuals who perform a solutions architect role and have one or more years of hands-on experience designing available, cost-efficient, fault-tolerant, and scalable distributed systems on AWS.",
    issuer: "AWS",
    Moreinfo:
      "https://aws.amazon.com/certification/certified-solutions-architect-associate/",
    QuickVeri:
      "https://www.credly.com/badges/9431a3af-19df-429d-ac40-62c191e42020",
  },
  {
    id: 1,
    title: "Network+ N10-007",
    image: "images/n.jpg",
    content:
      "This certification validates the knowledge and skills needed to troubleshoot, configure and manage wired and wireless networks found in companies around the world. CompTIA Network+ certifies a professional-level understanding of emerging technologies, including cloud and virtualization technologies.",
    issuer: "CompTIA",
    Moreinfo:
      "https://www.comptia.org/faq/network/what-is-comptia-network-certification#:~:text=CompTIA%20Network%2B%20is%20the%20only,in%20companies%20around%20the%20world.",
    QuickVeri:
      "https://www.credly.com/badges/9a458e95-1fb2-445e-981d-954ac1d30b7c",
  },

  {
    id: 3,
    title: "Developer- Associate",
    image: "images/1.jpg",
    content:
      "This AWS Associate certification is intended for individuals who perform a development role and have one or more years of hands-on experience developing and maintaining an AWS-based application. It also validates proficiency in developing, deploying, and debugging cloud-based applications using AWS.",
    issuer: "AWS",
    Moreinfo:
      "https://aws.amazon.com/certification/certified-developer-associate/",
    QuickVeri:
      "https://www.credly.com/badges/2033f404-47d0-48c2-8867-cbfb0ac5628a",
  },
  {
    id: 4,
    title: "Cybersecurity Fundamentals",
    image: "images/cy.jpg",
    content:
      "A foundational understanding of cybersecurity concepts, and practices. This includes cyber threat groups, types of attacks, overall security strategies, cryptography, and common approaches that organizations take to prevent, detect, and respond to cyber attacks. ",
    issuer: "IBM",
    Moreinfo:
      "https://badges.mybluemix.net/badge/b8810a57-2c5a-4bbc-81f1-9bfc649ad13d",
    QuickVeri:
      "https://www.credly.com/badges/e0a88fb0-62cf-494f-bebe-b467dea7c5a7",
  },
  {
    id: 5,
    title: "Trustee",
    image: "images/bya.jpg",
    content:
      "This certification exposes participants to the role of privileged accounts in the threat landscape and privileged access management solutions to mitigate risk and protect organizations.",
    issuer: "CyberArk",
    Moreinfo: "https://training.cyberark.com/exam/level-1-Trustee",
    QuickVeri: "",
  },
  {
    id: 6,
    title: "A+ 1001-1002",
    content:
      "CompTIA A+ certification is earned by passing a set of two exams – created by IT industry professionals – that validate skills needed for entry-level IT jobs. CompTIA A+ candidates are better prepared to troubleshoot and problem solve a wider variety of issues, ranging from networking and operating systems to mobile devices and security. ",
    issuer: "CompTIA",
    image: "images/a.jpg",
    Moreinfo: "https://www.comptia.org/faq/a/what-is-comptia-a-certification",
    QuickVeri: "",
  },
  {
    id: 7,
    title: "Cisco Networking Academy",
    image: "images/ci.jpg",
    issuer: "Kern High School District",
    content:
      "A Certificate of Completion is awarded to all Students and Instructors who satisfactorily complete a Cisco Networking Academy® course. This is indicated by a “complete” marked in the Cisco® NetSpace grade book for the relevant course.",
    Moreinfo:
      "https://ccnacomplete.blogspot.com/2014/01/certificates-of-completion-and-letters.html#:~:text=A%20Certificate%20of%20Completion%20is%20awarded%20to%20all,is%20not%20the%20same%20as%20a%20Cisco%20certification.",
    QuickVeri: "",
  },

  {
    id: 8,
    title: "TestOut PC Pro",
    image: "images/P.jpg",
    issuer: "TestOut",
    content:
      "The main purpose of the TestOut PC Pro certification is to verify necessary skills to work as an IT support professional. In an IT support job, you’ll be asked to install, repair, configure, secure, and manage computer hardware, operating systems, and software in home or corporate environments. ",
    Moreinfo: "https://w3.testout.com/pro-certifications/pc-pro",
    QuickVeri: "",
  },
];
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 1200, itemsToShow: 3 },
];
function Pricing() {
  return (
    <section id="prices">
      <ScrollAnimation
        animateIn="bounceInRight"
        animateOut="bounceInLeft"
        // animateOnce={true}
      >
        <div className="container ">
          <div>
            <Pagetitle
              title="Credentials"
              image="/images/com-gif-maker-unscreen.gif"
            />
            <div className="row tempcolor">
              <Carousel
                className=""
                style={{ backgroundColor: "#353D43" }}
                breakPoints={breakPoints}
              >
                {priceboxData.map((pricebox) => (
                  <div className="col-md-12">
                    <Pricebox key={pricebox.id} priceboxItem={pricebox} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </section>
  );
}
export default Pricing;
