import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";
import "./styles.css";
import ScrollAnimation from "react-animate-on-scroll";

const aboutContent = {
  name: "Bolby",
  avatarImage: "/images/me2.gif",
  hello: "Hello There!!",
  content:
    "I am a recent graduate, and I enjoy learning and inventing new things with a purpose. In addition, I am enthusiastic about innovation and proud to be bold.",
  content2: "Continue scrolling to discover more about me 😊.",
};

const progressData = [
  {
    id: 1,
    title: "Development",
    percantage: 85,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "UI/UX Design",
    percantage: 95,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Photography",
    percantage: 70,
    progressColor: "#6C6CE5",
  },
];

const counterData = [
  {
    id: 1,
    title: "Industry Standard Certifications",
    count: 4,
    icon: "icon-fire",
  },
  {
    id: 3,
    title: "GPA Computer Science",
    count: 4.0,
    icon: "icon-people",
  },
  {
    id: 4,
    title: "Awards",
    count: 10,
    icon: "",
  },
];

function About() {
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      // animateOnce={true}
    >
      <section id="about">
        <div className="container">
          <Pagetitle title="About Me" />
          <div className="row">
            <div className="col-md-3">
              <div className="middle2 text-md-left">
                <img
                  // className="photoHero"
                  style={{ height: "200px" }}
                  src={aboutContent.avatarImage}
                  alt={aboutContent.name}
                />
              </div>
              <div
                className="spacer d-md-none d-lg-none"
                data-height="30"
              ></div>
            </div>

            <div className="col-md-9 triangle-left-md triangle-top-sm ">
              <div
                className="
              rounded bg-white shadow-dark padding-30"
              >
                <div className="row">
                  <div className=" col-md-12">
                    <p className="hello">{aboutContent.hello}</p>

                    <p>{aboutContent.content}</p>
                    <p>{aboutContent.content2}</p>

                    {/* <div className="mt-3">
                    <a href="!#" className="btn btn-default">
                      Download CV
                    </a>
                  </div> */}
                    <div
                      className="spacer d-md-none d-lg-none"
                      data-height="30"
                    ></div>
                  </div>
                  {/* <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* //TODO: maybe add a highlight of what things we accomplished like gpa or awards as a number but this is a extended goal */}
          {/* <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-4 col-sm-3">
              <TrackVisibility>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div> */}
        </div>
      </section>
    </ScrollAnimation>
  );
}

export default About;
