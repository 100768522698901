import React from "react";
import "./App.css";

function Service({ service }) {
  const { title, content, icon, color, contentColor, symbol } = service;
  const hexToRgb = (hex) => {
    const temp = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
    return temp.join(",");
  };

  const Emoji = (props) => (
    <h1
      className="emoji"
      role="img"
      aria-label={props.label ? props.label : ""}
      aria-hidden={props.label ? "false" : "true"}
      height={60}
    >
      {props.symbol}
    </h1>
  );

  return (
    <div
      className={
        contentColor === "light"
          ? "service-box rounded data-background padding-30 text-center text-light shadow-blue heights"
          : "service-box rounded data-background padding-30 text-center shadow-blue"
      }
      data-color="#6C6CE5"
      style={{
        background: color,
        boxShadow:
          "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
      }}
    >
      <img
        style={{
          height: 96,
          width: 96,
        }}
        src={icon}
        alt={title}
      />
      <div className="height21">
        <h3 className="mb-3 mt-0">{title}</h3>
        <p className="mb-0">{content}</p>
        <Emoji symbol={symbol} label="sheep" />
      </div>
    </div>
  );
}

export default Service;
