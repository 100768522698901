import React from "react";
import { Link } from "react-router-dom";

import "./App.css";
// import Logos from "./jt-1.svg";
function Logo({ logoSource, bootstrapNav }) {
  if (bootstrapNav) {
    return <img className="photo" src={logoSource} alt="Bolby" />;
  }

  return (
    <div className="site-logo photo middle2">
      <img src={logoSource} alt="Bolby" />
    </div>
  );
}

export default Logo;
