import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
function Blog({ blogData }) {
  const {
    id,
    // category,
    title,
    date,
    author,
    image,
    content1,
    content2,
    images,
    link,
    desc,
  } = blogData;
  const [show, setShow] = useState(false);
  const handleMoreinfo = () => {
    const url = link;
    window.open(url, "_blank");
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div className="blog-item rounded bg-white shadow-dark heights">
        <div onClick={handleShow} className="teamSelector">
          <div className="thumb heights middle2">
            {/* <span className="category">{category}</span> */}

            <img
              src={image}
              style={{ height: 220, width: 335 }}
              alt="blog-title"
            />
          </div>
          <div className="details " style={{ height: 90 }}>
            <h4 className="my-0 title">
              {/* <Link
              to={`blogs/blog-details/${id}/${getNospaceTitle(filesource)}`}
            > */}
              {title}
              {/* </Link> */}
            </h4>
            <ul className="list-inline meta mb-0 mt-2">
              <li className="list-inline-item">{date}</li>
              <li className="list-inline-item">{author}</li>
            </ul>
          </div>
        </div>
        {/* modal to pop up */}
        <Modal
          size="lg"
          centered={true}
          // animation={true}
          show={show}
          onHide={handleClose}
          className="modal"
        >
          <Modal.Header closeButton={false} className="bg-white">
            <Modal.Title
              className=" rounded shadow-dark px-3 overflow-hidde"
              style={{ backgroundColor: "#66fcf1" }}
            >
              {title}
            </Modal.Title>
          </Modal.Header>
          <Carousel>
            {images &&
              images.map((tb, index) => {
                return (
                  <Carousel.Item interval={1000}>
                    <img
                      key={index}
                      className="d-block w-100"
                      src={tb}
                      alt="First slide"
                    />

                    {/* console.log(index); */}
                    <Carousel.Caption style={{ color: "black" }}>
                      {/* <h3 style={{ color: "black" }}>First slide label</h3> */}
                      <p
                        style={{
                          color: "rgb(102, 252, 241)",
                          fontFamily: "Helvet",
                          fontSize: 25,
                          fontWeight: "bold",
                          backgroundColor: "#fffff0",
                          borderRadius: 40,
                        }}
                      >
                        {desc[index]}
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
          </Carousel>
          {/* <Card.Title>Card Title</Card.Title> */}
          <Card className="bg-white ">
            {content1 == "" ? null : (
              <Card.Text className="m-3">{content1}</Card.Text>
            )}
            {content2 == "" ? null : (
              <Card.Text className="m-3">{content2}</Card.Text>
            )}
          </Card>
          {/* <Card.Text>{content2}</Card.Text> */}
          <Modal.Footer className="text-center middle2">
            {link == "" ? null : (
              <Button
                className="rainbow2"
                variant="primary"
                onClick={handleMoreinfo}
              >
                More Info
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </ScrollAnimation>
  );
}
export default Blog;
