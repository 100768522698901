import React, { useState } from "react";
// import "./App.css";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NJPG from "./n.jpg";

function Pricebox({ priceboxItem }) {
  // use this for the values of image title and more
  const { image, title, content, Moreinfo, QuickVeri, issuer } = priceboxItem;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleMoreinfo = () => {
    const url = Moreinfo;
    console.log(url);
    window.open(url, "_blank");
  };
  const handleQuick = () => {
    const url = QuickVeri;
    window.open(url, "_blank");
  };
  return (
    //TODO responsive of the card footer
    <div className="bg-white  tempcolor rounded shadow-dark text-center heights">
      <Card className=" bg-white rounded heights shadow-dark">
        <div className="card-img-wrapper card-img-top teamSelector">
          <Card.Img
            className="height3"
            variant="top"
            src={image}
            onClick={handleShow}
          />
        </div>{" "}
        <Card.Body
          style={{ flex: 1 }}
          className="heights mb-2 shadow-dark card-body"
        >
          <div className="height2">
            <Card.Title>{title}</Card.Title>
            <div className="row ">
              <div>
                <h5 className="middle2">
                  <i>Issued by: &nbsp; </i>
                  <u>
                    <i>{issuer}</i>
                  </u>
                </h5>
                <div className="" style={{ marginTop: 10 }}>
                  {" "}
                  {content}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="">
          <div className=" text-center container">
            <div className=" text-center middle2 row">
              <Button className=" mx-2 my-2" onClick={handleMoreinfo} variant="dark">
                More Info
              </Button>

              {QuickVeri == "" ? null : (
                <Button className="mx-2 my-2" onClick={handleQuick} variant="dark">
                  Quick Verify
                </Button>
              )}
            </div>
          </div>
        </Card.Footer>
      </Card>
      <Modal
        size="lg"
        // dialogClassName="modalcontainer"
        className="modalcontainer"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="bg-white" closeButton>
          <Modal.Title
            className=" rounded shadow-dark px-3 overflow-hidde"
            style={{ backgroundColor: "#66fcf1" }}
          >
            <div className="">
              <h2>{title}</h2>
            </div>
          </Modal.Title>
        </Modal.Header>
        <img width={1000} height={2000} src={image} alt={"hello works"} />
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
}
export default Pricebox;
