import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";

import Homepage3Dark from "./pages/Homepage3Dark";

// import Certifications from "./components/sections/Certifications";

import Works from "./components/sections/sectionworks";
import Cert from "./components/sections/sectioncertifications";

import "./App.scss";

function App() {
  return (
    <HashRouter basename="/">
      <Switch>
        <Route path="/" exact>
          <Homepage3Dark />
        </Route>

        <Route path="/moresoftware" exact></Route>
        <Route path="/moreproject" exact>
          <Works />
        </Route>

        <Route path="/morecertifications" exact>
          <Cert />
        </Route>
      </Switch>
    </HashRouter>
  );
}

export default App;
